import jsPDF from "jspdf";
import "jspdf-autotable";

import Logo from "../../assets/nhclogo.png";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (customDate) => {
  const year = customDate.getFullYear();
  const month = ("0" + (customDate.getMonth() + 1)).slice(-2);
  const day = ("0" + customDate.getDate()).slice(-2);
  const formatedDate = `${year}-${month}-${day}`;
  return formatedDate;
};

export const formatData = (report) => {
  let tableData = [];
  if (Object.keys(report).length === 5) {
    Object.keys(report).forEach((key) => {
      if (key === "coreHealthPremiums" || key === "spendingAccounts") {
        tableData.push(...report[key]);
      } else {
        tableData.push({ [`${key}`]: report[key] });
      }
    });
  } else if (report.length > 0) {
    report.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key === "coreHealthPremiums" || key === "spendingAccounts") {
          tableData.push(...item[key]);
        } else {
          tableData.push({ [`${key}`]: item[key] });
        }
      });
    });
  }
  return tableData;
};

export const generatePDF = (report, month, year) => {
  // initialize jsPDF
  const doc = new jsPDF();
  let pageSize = doc.internal.pageSize;
  let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  jsPDF.autoTableSetDefaults({
    theme: "striped",
    tableWidth: "auto",
    columnWidth: "auto",
    showHead: "everyPage",
    rowPageBreak: "avoid",
    pageBreak: "auto",
    didDrawPage: function (data) {
      //header
      doc.addImage(Logo, "PNG", 115, 5, 90, 7);

      //footer
      let str = `${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.text(str, pageWidth / 2, pageHeight - 2);
    },
    margin: { top: 30, bottom: 20 },
    styles: {
      overflow: "linebreak",
    },
    columnStyles: { 1: { halign: "center", cellWidth: 20 } },
    headStyles: { fillColor: "#223e7f" },
  });

  const width = doc.internal.pageSize.getWidth();
  doc.text(`Distribution report ${months[month]} ${year}`, width / 2, 25, {
    align: "center",
  });

  if (Object.keys(report).length === 5) {
    const tableColumnSummary = ["Type", "Amount"];
    const summaryRows = [];

    // let summaryData = [
    //   "CoreHealth Premiums",
    //   `$${report.coreHealthPremiumsTotal}`,
    // ];
    // summaryRows.push(summaryData);

    let summaryData = [
      "Spending Accounts Total",
      `$${report.spendingAccountsTotal}`,
    ];
    summaryRows.push(summaryData);

    summaryData = [" Total", `$${report.total}`];
    summaryRows.push(summaryData);

    doc.setFontSize(12);
    doc.text("Summary report", 15, 40);
    doc.autoTable({
      columns: tableColumnSummary,
      body: summaryRows,
      startY: 45,
      // tableWidth: 100,
    });

    const tableHeaders = ["Description", "Amount", "Date"];
    // const coreHealthPremiumsRows = [];
    //
    // report.coreHealthPremiums.forEach((item) => {
    //   const coreHealthData = [item.description, `$${item.amount}`, item.date];
    //   coreHealthPremiumsRows.push(coreHealthData);
    // });

    let finalY = doc.previousAutoTable.finalY;

    doc.setFontSize(12);
    // doc.text("CoreHealth Premiums Report", 15, finalY + 10);
    // doc.autoTable({
    //   columns: tableHeaders,
    //   body: coreHealthPremiumsRows,
    //   startY: finalY + 15,
    // });

    const spendingAccountsRows = [];

    report.spendingAccounts.forEach((item) => {
      const spendingAccountsData = [
        item.description,
        `$${item.amount}`,
        item.date,
      ];
      spendingAccountsRows.push(spendingAccountsData);
    });

    finalY = doc.previousAutoTable.finalY;

    doc.setFontSize(12);
    doc.text("Spending Accounts Report", 15, finalY + 10);
    doc.autoTable({
      columns: tableHeaders,
      body: spendingAccountsRows,
      startY: finalY + 15,
    });
  } else {
    report.forEach((item, idx) => {
      let finalY = 20;
      if (idx !== 0) {
        finalY = doc.previousAutoTable.finalY;
        if (pageHeight - finalY < 100) {
          doc.addPage();
          finalY = 20;
        }
      }
      const name = item.company ? "Company" : "Agent";
      doc.setFontSize(15);
      doc.text(
        `${name}: ${item.company || item.agentReportingCode}`,
        15,
        finalY + 20
      );
      const tableColumnSummary = ["Type", "Amount"];
      const summaryRows = [];

      // let summaryData = [
      //   "CoreHealth Premiums",
      //   `$${item.coreHealthPremiumsTotal}`,
      // ];
      // summaryRows.push(summaryData);

      let summaryData = [
        "Spending Accounts Total",
        `$${item.spendingAccountsTotal}`,
      ];
      summaryRows.push(summaryData);

      summaryData = ["Total", `$${item.total}`];
      summaryRows.push(summaryData);

      doc.autoTable({
        columns: tableColumnSummary,
        body: summaryRows,
        startY: finalY + 25,
      });

      const tableHeaders = ["Description", "Amount", "Date"];

      // if (item.coreHealthPremiums.length > 0) {
      //   const coreHealthPremiumsRows = [];
      //
      //   item.coreHealthPremiums.forEach((el) => {
      //     const coreHealthData = [el.description, `$${el.amount}`, el.date];
      //     coreHealthPremiumsRows.push(coreHealthData);
      //   });
      //
      //   finalY = doc.previousAutoTable.finalY;
      //
      //   doc.setFontSize(12);
      //   doc.text("CoreHealth Premiums Report", 15, finalY + 15);
      //   doc.autoTable({
      //     columns: tableHeaders,
      //     body: coreHealthPremiumsRows,
      //     startY: finalY + 20,
      //   });
      // }

      if (item.spendingAccounts.length > 0) {
        const spendingAccountsRows = [];

        item.spendingAccounts.forEach((el) => {
          const spendingAccountsData = [
            el.description,
            `$${el.amount}`,
            el.date,
          ];
          spendingAccountsRows.push(spendingAccountsData);
        });

        finalY = doc.previousAutoTable.finalY;

        doc.setFontSize(12);
        doc.text("Spending Accounts Report", 15, finalY + 15);
        doc.autoTable({
          columns: tableHeaders,
          body: spendingAccountsRows,
          startY: finalY + 20,
        });
      }
    });
  }

  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  // we define the name of our PDF file.
  doc.save(`report_${dateStr}.pdf`);
};
