import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import {FaSortDown, FaSortUp} from "react-icons/fa";

export const EnrollmentTable = ({ currentItems, handleDeleteClick }) => {
    const columns = React.useMemo(() => [
        {
            Header: 'Company',
            accessor: 'policyName',
        },
        {
            Header: 'Client',
            accessor: (row) => `${row.clientFirstName} ${row.clientLastName}`,
            id: 'client',
        },
        {
            Header: 'Email',
            accessor: 'clientEmail',
        },
        {
            Header: 'Status',
            accessor: (row) => row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase(),
            id: 'status',
        },
        {
            Header: 'Created Date',
            accessor: 'createdAt',
        },
        {
            Header: 'View',
            accessor: 'id',
            id: 'view',
            Cell: ({ cell }) => {
                const id = cell.value;
                const item = currentItems.find(item => item.id === id);
                return item && item.status === "pending" ? (
                    <a
                        href={`/enroll/${id}?mode=edit`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-outline-primary btn-sm"
                    >
                        View
                    </a>
                ) : null;
            },
        },
        {
            Header: 'Delete',
            accessor: 'id',
            id: 'delete',
            Cell: ({ cell }) => {
                const id = cell.value;
                const item = currentItems.find(item => item.id === id);
                return item && item.status === "pending" ? (
                    <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => handleDeleteClick(id)}
                    >
                        Delete
                    </button>
                ) : null;
            },
        },
    ], [currentItems, handleDeleteClick]);

    const data = React.useMemo(() => currentItems.filter(item => item.id), [currentItems]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <table {...getTableProps()} className="table table-hover pending-enrollment-table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                    </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} key={row.id}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination">
                <button className="pagination-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button className="pagination-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button className="pagination-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button className="pagination-button" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                <select
                    className="pagination-select"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(size => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};
