import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/nhclogo.png";

export const formatData = (report) => {
  let data = [];
  Object.keys(report).forEach((item) => {
    if (item === "coreHealthPremiums" || item === "spendingAccounts") {
      data.push(...report[item]);
    } else {
      data.push({ [`${item}`]: report[item] });
    }
  });
  return data;
};

export const formatData1 = (report) => {
  let data = [];
  report.company !== undefined
    ? data.push({ Company: report.company })
    : data.push({ Agent: report.agentReportingCode });
  data.push({ "Spending Accounts Total": report.spendingAccountsTotal });
  // data.push({ "CoreHealth Premiums Total": report.coreHealthPremiumsTotal });
  data.push({ Total: report.total });
  // data.push(...report.coreHealthPremiums);
  data.push(...report.spendingAccounts);

  return data;
};

export const generatePDF = (report) => {
  // initialize jsPDF
  const doc = new jsPDF();

  jsPDF.autoTableSetDefaults({
    theme: "striped",
    tableWidth: "auto",
    columnWidth: "auto",
    showHead: "everyPage",
    rowPageBreak: "avoid",
    pageBreak: "auto",
    didDrawPage: function (data) {
      //header
      doc.addImage(Logo, "PNG", 115, 5, 90, 7);

      //footer
      let str = `${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.text(str, pageWidth / 2, pageHeight - 2);
    },
    margin: { top: 30, bottom: 20 },
    styles: {
      overflow: "linebreak",
    },
    columnStyles: { 1: { halign: "center", cellWidth: 20 } },
    headStyles: { fillColor: "#223e7f" },
  });

  const width = doc.internal.pageSize.getWidth();
  doc.setFontSize(15);
  doc.text(
    ` Commission Report - ${report.company || report.agentReportingCode}`,
    width / 2,
    25,
    {
      align: "center",
    }
  );

  const tableColumnSummary = ["Type", "Amount"];
  const summaryRows = [];

  // let summaryData = [
  //   "CoreHealth Premiums",
  //   `$${report.coreHealthPremiumsTotal}`,
  // ];
  // summaryRows.push(summaryData);

  let summaryData = ["Spending Accounts Total", `$${report.spendingAccountsTotal}`];
  summaryRows.push(summaryData);

  summaryData = [" Total", `$${report.total}`];
  summaryRows.push(summaryData);

  doc.setFontSize(12);
  doc.text("Summary report", 15, 40);
  doc.autoTable({
    columns: tableColumnSummary,
    body: summaryRows,
    // tableWidth: 100,
    startY: 45,
  });

  let finalY = doc.previousAutoTable.finalY;
  const tableHeaders = ["Description", "Amount", "Date"];
  //
  // if (report.coreHealthPremiums.length > 0) {
  //   const coreHealthPremiumsRows = [];
  //
  //   report.coreHealthPremiums.forEach((item) => {
  //     const coreHealthData = [item.description, `$${item.amount}`, item.date];
  //     coreHealthPremiumsRows.push(coreHealthData);
  //   });
  //
  //   finalY = doc.previousAutoTable.finalY;
  //
  //   doc.setFontSize(12);
  //   doc.text("CoreHealth Premiums Report", 15, finalY + 15);
  //   doc.autoTable({
  //     columns: tableHeaders,
  //     body: coreHealthPremiumsRows,
  //     startY: finalY + 20,
  //   });
  // }

  if (report.spendingAccounts.length > 0) {
    const spendingAccountsRows = [];

    report.spendingAccounts.forEach((item) => {
      const spendingAccountsData = [
        item.description,
        `$${item.amount}`,
        item.date,
      ];
      spendingAccountsRows.push(spendingAccountsData);
    });

    finalY = doc.previousAutoTable.finalY;

    doc.setFontSize(12);
    doc.text("Spending Accounts Report", 15, finalY + 15);
    doc.autoTable({
      columns: tableHeaders,
      body: spendingAccountsRows,
      startY: finalY + 20,
    });
  }

  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  // we define the name of our PDF file.
  doc.save(`report_${dateStr}.pdf`);
};
